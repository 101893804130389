import { SignInButton, useUser } from "@clerk/nextjs";
import { useEffect, useState } from "react";

import styles from "./AuthStatus.module.scss";
import { deleteCookie, getCookie } from "utils";
import Button from "@/components/ui/buttons/Button/Button";
import { TiWarningOutline } from "react-icons/ti";

export default function AuthStatus() {
  const [authAttemptDetected, setAuthAttemptDetected] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const { isSignedIn } = useUser();

  useEffect(() => {
    const authAttemptCookie = getCookie("auth_attempt_detected");

    if (authAttemptCookie) {
      setAuthAttemptDetected(true);
    }

    if (!authAttemptCookie || !!isSignedIn) {
      deleteCookie({ name: "auth_attempt_detected" });
    }
  }, []);

  if (!authAttemptDetected || !!isSignedIn) {
    return null;
  }

  const handlePopupClose = () => {
    setIsOpen(false);
    deleteCookie({ name: "auth_attempt_detected" });
  };

  if (authAttemptDetected && !isSignedIn && isOpen) {
    return (
      <div className={styles.container}>
        <div className={`${styles.closeButtonContainer}`}>
          <Button classes={[styles.closeButton]} onClick={handlePopupClose}>
            X
          </Button>
        </div>
        <TiWarningOutline size={64} className={styles.icon} />
        <p>
          Your attempt to sign up with Google was unsuccessful. You may have
          tried to sign up with an existing account--try signing in instead of
          signing up! If this doesn't work, please contact us and let us know.
        </p>
        <SignInButton mode="modal">
          <Button classes={["sign-in-button"]} onClick={handlePopupClose}>
            Sign In
          </Button>
        </SignInButton>
      </div>
    );
  }
}
